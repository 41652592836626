import React from 'react';

//React-router-dom
import {NavLink, Link} from 'react-router-dom';

//Styles
import './MobileNavigation.scss';
import { useTheme } from "../hooks/useTheme";


function MobileNavigation(props){
  const { ref } = useTheme();

    return(
        <div className="mobileMenu">
            <div className="container">
                <h4>Golden Rule Construction</h4>

                <ul>
                    <li><Link 
                    // to="/" exact 
                    activeClassName="active" onClick={() => {
                         window.scrollTo({
                           top: 0,
                           left: 0,
                           behavior: "smooth",
                         });
                        props.closeMobileMenu()
                    }}>Home</Link></li>
                    <li><Link 
                    // to="/about" 
                    exact activeClassName="active" onClick={() => {
                        ref[0].current.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "nearest",
                      })
                        props.closeMobileMenu()
                    }}>About</Link></li>
                    <li><Link 
                    // to="/portfolio" 
                    exact activeClassName="active" onClick={() => {
                        ref[1].current.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "nearest",
                      })
                        props.closeMobileMenu()
                    }}>Portfolio</Link></li>
                    <li><Link 
                    // to="/contact" 
                    exact activeClassName="active" onClick={() => {
                        ref[2].current.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "nearest",
                      })
                        props.closeMobileMenu()
                    }}>Contact</Link></li>

                    {/* <li><Link 
                    // to="/rental" 
                    exact activeClassName="active" onClick={() => {
                        
                        props.closeMobileMenu()
                    }}>Prenájom</Link></li> */}
                    
                </ul>
            </div>

        </div>
    )
}

export default MobileNavigation;