import React, { useEffect, useState } from 'react';
import client from "../client";


import { Link } from 'react-router-dom';

//Styles
import './Footer.scss';

//Icons
import BackToTop from '../Icons/BackToTop';
import { useTheme } from '../hooks/useTheme';

function Footer(props){
  const [data, setData] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "footer"]{
        title,
        slug,
        body,
        image{
          asset->{
            _id,
            url
          },
        },
        hexCode,
      }`
      )
      .then((data) => setData(data))
      .catch(console.error);
  }, []);

  console.log(data)
  const { ref } = useTheme();

  return (
    <div className="footer">
      <div className="container">
        <div className="container-inner">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d97838.895366572!2d-75.01390473932514!3d39.975701923109305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c14a98ac5efd09%3A0xb678295f4be1e444!2sMoorestown%2C%20NJ%2008057!5e0!3m2!1sen!2sus!4v1670458237080!5m2!1sen!2sus"
            width="80%"
            height="450"
            frameBorder="0"
            allowFullScreen="false"
            aria-hidden="false"
            tabIndex="0"
            zoom="0"
          ></iframe>

          <div className="column-wrapper">
            <div className="column">
              <h5>Navigation</h5>
              <Link onClick={() => props.scrollToTop()}>Home</Link>
              <Link
                onClick={() =>
                  ref[0].current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "nearest",
                  })
                }
              >
                About
              </Link>
              <Link
                onClick={() =>
                  ref[1].current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "nearest",
                  })
                }
              >
                Portfolio
              </Link>
              <Link
                onClick={() =>
                  ref[2].current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "nearest",
                  })
                }
              >
                Contact
              </Link>
            </div>

            <div className="column">
              <h5>Contact</h5>
              <a href="tel:2153604303">(215) 360 4303</a>
              <a href="mailto:don@goldenrulecc.com">don@goldenrulecc.com</a>
            </div>

            <div className="column">
              <h5>Top Projects</h5>
              {data &&
                data.map((item, index) => (
                  <p>{item.title}</p>
                ))}
              {/* <p>Kitchens</p>
              <p>Bathrooms</p>
              <p>Electrical Lighting</p>
              <p>Decks</p> */}
            </div>

            <div className="column">
              <h5>Areas of Work</h5>
              <p>Pennsylvania</p>
              <p>New Jersey</p>
              <p>Delaware</p>
            </div>
          </div>

          <div className="copyright">
            <p>&copy; Golden Rule Construction</p>

            <div className="back-to-top">
              <BackToTop scrollToTop={props.scrollToTop} />
              <p onClick={props.scrollToTop}>Back to top</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;