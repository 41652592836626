import { useRef } from "react";
import { createContext, useReducer } from "react";

export const ThemeContext = createContext();

const fontsArr = [
  "Kdam Thmor Pro",
  "Noto Serif",
  "League Gothic",
  "Dancing Script",
  "Lobster",
  "Staatliches",
  "Abril Fatface",
  "Alfa Slab One",
  "Caveat",
  "Fascinate Inline",
  "Indie Flower",
  "Permanent Marker",
  "Secular One",
];

const themeReducer = (state, action) => {
  switch (action.type) {
    // case "CHANGE_COLOR":
    //   return { ...state, color: action.payload };
    // case "CHANGE_MODE":
    //   return { ...state, darkMode: !state.darkMode };
    case "TOGGLE_MOBILE_MENU":
      return { ...state, isMobileMenuOpen: action.payload };
    case "CHANGE_REFS":
      return { ...state, ref: action.payload };
    // case "CHANGE_FONT":
    //   return {
    //     ...state,
    //     font: fontsArr[Math.floor(Math.random() * fontsArr.length)],
    //   };
    default:
      return state;
  }
};

export function ThemeProvider({ children }) {
  const linkRefOne = useRef()
  const [state, dispatch] = useReducer(themeReducer, {
    color: "#F5F5F5",
    darkMode: false,
    ref: [],
    isMobileMenuOpen: false
  });

  const toggleMobileMenu = (boolean) => {
    dispatch({ type: "TOGGLE_MOBILE_MENU", payload: boolean });
  };
  const changeRefs = (element) => {
    dispatch({ type: "CHANGE_REFS", payload: element });
  };
  // const changeColor = (color) => {
  //   dispatch({ type: "CHANGE_COLOR", payload: color });
  // };

  // const changeMode = () => {
  //   dispatch({ type: "CHANGE_MODE" });
  // };

  // const changeFont = () => {
  //   dispatch({ type: "CHANGE_FONT" });
  // };

  return (
    <ThemeContext.Provider
      value={{
        ...state,
        // changeColor,
        // changeMode,
        // changeFont,
        changeRefs,
        toggleMobileMenu,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}
