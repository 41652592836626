import React, { useEffect, useState } from 'react';
import client from "../client";


//Slick Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

//Slider image
import SliderImage from '../Img/Slider-Home/excavator.jpg';
import Image1 from "../Img/images/interior-barn-door-.jpg";
import Image2 from "../Img/images/kitchen-coffee-bar-area.jpg";
import Image3 from "../Img/images/poway-kitchen-remodel-with-island-seating.jpg";
import Image4 from "../Img/images/poway-secondary-bathroom-remodel.jpg";
import Image5 from "../Img/images/wet-bar-makeover.jpg";
import Image6 from "../Img/images/Kitchen-Remodel-Temecula-Classic-Home-Improvements.jpg";

//Arrows
import Next from '../Icons/Next';
import Prev from '../Icons/Prev';

//Styles
import './PortfolioSlider.scss'

function PortfolioSlider(){
   const [data, setData] = useState([]);

   useEffect(() => {
     client
       .fetch(
         `*[_type == "portfolio"]{
        title,
        slug,
        body,
        description,
        mainImage{
          asset->{
            _id,
            url
          },
        },
        hexCode,
      }`
       )
       .then((data) => setData(data))
       .catch(console.error);
   }, []);

   console.log(data);

    //Custom arrows
    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
          {...props}
          className={
            "slick-prev slick-arrow" +
            (currentSlide === 0 ? " slick-disabled" : "")
          }
          aria-hidden="true"
          aria-disabled={currentSlide === 0 ? true : false}
          type="button"
          id="prevSlide"
        >
          <Prev />
        </button>
      );

      const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
          {...props}
          className={
            "slick-next slick-arrow" +
            (currentSlide === slideCount - 1 ? " slick-disabled" : "")
          }
          aria-hidden="true"
          aria-disabled={currentSlide === slideCount - 1 ? true : false}
          type="button"
          id="nextSlide"
        >
          <Next />
        </button>
      );

    const settings ={
        dots:false,
        arrows:true,
        prevArrow:<SlickArrowLeft slideCount={data.length} />,
        nextArrow:<SlickArrowRight slideCount={data.length} />,
        autoplay:false,
        slidesToShow:3,
        slidesToScroll:1,
        variableWidth:true,
        responsive: [
            {
              breakpoint: 1100,
              settings: {
                variableWidth:false
              }
            },
            {
              breakpoint:950,
              settings: {
                slidesToShow:2,
                slidesToScroll:2,
                variableWidth:false
              }
            },
            {
              breakpoint:500,
              settings: {
                slidesToShow:1,
                slidesToScroll:1,
                variableWidth:false
              }
            }
          ]
    }

    return (
      <div className="portfolioSlider">
        <Slider {...settings}>
          {data &&
            data.map((item, index) => (
              <div className="slide">
                <div className="content">
                  <img
                    src={item.mainImage.asset.url}
                    alt="portfolio-example"
                    loading="lazy"
                    title="Golden Rule Construction"
                  />
                  <div className="text">
                    <p>{item.description[0].children[0].text}</p>
                    <h5>{item.title}</h5>

                    <p>{item.body[0].children[0].text}</p>

                    {/* <a href="#sa">Show More</a> */}
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    );
}

export default PortfolioSlider;