import React, { useRef, useState } from "react";
import client from "../client";

//Styles
import "../Views/Home.scss";

//Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";

//SVG icons
import Dots from '../Icons/Dots';
import Square from '../Icons/Square';

import { NavLink, Link } from "react-router-dom";




function ContactHome({ linkRefThree }) {

  return (
    <div ref={linkRefThree} className="contact section">
      <div className="container">
        <h2>
          Contact <Dots />
        </h2>

        <div className="container-inner">
          <div className="wrapper">
            <div className="info">
              <div className="column">
                <div className="heading">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  <h4>Where we are</h4>
                </div>
                <p>PA - NJ - DE</p>
                {/* <p>927 01</p>
                    <p>Šaľa</p> */}
              </div>

              <div className="column">
                <div className="heading">
                  <FontAwesomeIcon icon={faPhoneAlt} />
                  <h4>Contact</h4>
                </div>
                <p>Don Heilman</p>
                <p>215 360 4303</p>
                <p>don@goldenrulecc.com</p>
              </div>

              <div className="column">
                <div className="heading">
                  <FontAwesomeIcon icon={faClock} />
                  <h4>Working Time</h4>
                </div>
                <p>Monday - Friday</p>
                <p>8:00 - 4:30</p>
              </div>
            </div>

            <div className="form">
              <form
                action="https://getform.io/f/cbb674e5-7d9c-4a49-aa8b-aff7eba1647b"
                method="POST"
              >
                <input name="name" type="text" placeholder="Name" />
                <input name="email" type="email" placeholder="Email" />

                <textarea name="message" placeholder="Message"></textarea>

                {/* add hidden Honeypot input to prevent spams */}
                {/* <input type="hidden" name="_gotcha" style="display:none !important"></input> */}

                <button type="submit" className="send">
                  <FontAwesomeIcon icon={faPaperPlane} />
                  <NavLink to="">Send</NavLink>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Square />
    </div>
  );
}

export default ContactHome