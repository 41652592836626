import React, { useEffect, useState } from 'react';
import client from "../client"
import styled from "styled-components";

//Slick Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

//Styles
import './Carousel.scss'

function Carousel(){
  const [data, setData] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"]{
        title,
        slug,
        body,
        mainImage{
          asset->{
            _id,
            url
          },
        },
        hexCode,
      }`
      )
      .then((data) => setData(data))
      .catch(console.error);
  }, []);

    // console.log(data[0].mainImage.asset.url, '-----------');

  const settings = {
    dots: true,
    arrows: false,
    autoplay: false,
  };

  return (
    <div className="slider-main">
      <Slider {...settings}>
        {data &&
          data.map((item, index) => {
            const Button = styled.div`
              background: linear-gradient(
                  rgba(0, 0, 0, 0.25),
                  rgba(0, 0, 0, 0.15)
                ),
                url(${item.mainImage.asset.url}) center center;
            `;
            return (
              <Button
                className="slide"
                loading="lazy"
                title="Golden Rule Construction"
              >
                {/* <img src={item.mainImage.asset.url} /> */}
                <div className="text">
                  <p>{item?.body[0].children[0].text}</p>
                  <h1>{item.title}</h1>
                </div>
              </Button>
            );
})}
      </Slider>
    </div>
  );
}

export default Carousel;